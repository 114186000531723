import { useState, useEffect, useCallback } from 'react'
import Axios from 'axios'

import { VizznResponse } from 'types/api'

export type Res<T> =
  | { data: T; loading: false; error: false; reFetch: () => Promise<void> }
  | { data: null; loading: true; error: false; reFetch: () => Promise<void> }
  | { data: null; loading: false; error: true; reFetch: () => Promise<void> }

export const useGet = <T,>(endpoint: string): Res<T> => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState(false)

  const get = useCallback(async () => {
    try {
      const url = `https://${process.env.REACT_APP_API_URL}/v1/${endpoint}`

      // TODO: Add auth headers to this

      const { data } = await Axios.get<VizznResponse<T>>(url)

      setData(data.data)
    } catch (error) {
      setData(null)
      setError(true)
      return
    }
  }, [endpoint])

  useEffect(() => {
    get()
  }, [get])

  const reFetch = useCallback(() => get(), [get])
  if (data) {
    return { data, loading: false, error: false, reFetch }
  } else if (error) {
    return { data: null, loading: false, error: true, reFetch }
  } else {
    return { data: null, loading: true, error: false, reFetch }
  }
}
