import React from 'react'

type Props = {
  reFetch: () => void
}

export const ErrorRetry = ({ reFetch }: Props) => {
  return (
    <>
      <span>An Error has occured!</span>
      <button className="btn btn-primary" onClick={reFetch}>
        Re-Try
      </button>
    </>
  )
}
