import React from 'react'
import { RouteComponentProps, useLocation } from 'react-router'
import { useGet } from 'hooks/useGet'
import { api } from 'api'
import { FormSubmission } from '../atoms/FormSubmission'
import { Template } from 'types/template'
import { ErrorRetry } from 'atoms/ErrorRetry'

type Props = RouteComponentProps<{ formId: string; templateId: string }>

type Values = {
  submissionData: object
  isDraft: boolean
  dispatchEventId?: string
}

export const NewFormSubmission = ({ match }: Props) => {
  const { formId, templateId } = match.params
  const template = useGet<Template>(`forms/${formId}/templates/${templateId}`)
  const location = useLocation()
  const siteMapShapeId = new URLSearchParams(location.search).get('siteMapShapeId')
  const dispatchEventId = new URLSearchParams(location.search).get('dispatchEventId')

  if (template.loading) return <span>Loading...</span>
  if (template.error) return <ErrorRetry reFetch={template.reFetch} />

  const handleSubmit = async (values: Values) => {
    return api.create({
      isDraft: values.isDraft,
      submissionData: values.submissionData,
      formId,
      templateId,
      dispatchEventId: dispatchEventId || undefined,
      siteMapShapeId: siteMapShapeId || undefined,
    })
  }
  return (
    <div style={{ margin: 10, width: 'calc(100% - 20px)' }}>
      <h3>Create Submission</h3>
      <FormSubmission handleSubmit={handleSubmit} formioJson={template.data.formioJson} mode="new" />
    </div>
  )
}
