import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useGet } from 'hooks/useGet'
import { Template, Submission } from 'types/template'
import { FormSubmission } from '../atoms/FormSubmission'
import { api } from 'api'
import { ErrorRetry } from 'atoms/ErrorRetry'

type Props = RouteComponentProps<{ formId: string; templateId: string; submissionId: string }>

type Values = {
  submissionData: object
}

export const EditFormSubmission = ({ match }: Props) => {
  const { formId, templateId, submissionId } = match.params
  const template = useGet<Template>(`forms/${formId}/templates/${templateId}`)
  const onGoingSubmission = useGet<Submission>(`forms/${formId}/templates/${templateId}/submissions/${submissionId}`)

  if (template.loading || onGoingSubmission.loading) return <span>Loading...</span>
  if (template.error) return <ErrorRetry reFetch={template.reFetch} />
  if (onGoingSubmission.error) return <ErrorRetry reFetch={onGoingSubmission.reFetch} />

  const handleSubmit = async (values: Values) => {
    return api.edit({
      submissionData: values.submissionData,
      formId,
      templateId,
      submissionId,
    })
  }

  return (
    <div style={{ margin: 10, width: 'calc(100% - 20px)' }}>
      <div>
        <div className="d-flex justify-content-between my-2">
          <h3>Edit Submission</h3>
          <div className="d-flex align-items-center">
            <span
              className="badge badge-success mx-2 p-2"
              style={{ backgroundColor: `#${onGoingSubmission.data.status.bgColor}` }}
            >
              {onGoingSubmission.data.status.name}
            </span>{' '}
            <button className="btn btn-dark" onClick={() => window.history.back()}>
              Back
            </button>
          </div>
        </div>
        <div className="alert alert-warning" role="alert">
          <h3>Warning!</h3>
          Updating a Form Submission that has already been submitted will not rerun any actions.
        </div>
      </div>
      <FormSubmission
        showSaveAsDraft={false}
        handleSubmit={handleSubmit}
        formioJson={template.data.formioJson}
        submissionData={onGoingSubmission.data.formioData}
        mode="edit"
      />
    </div>
  )
}
