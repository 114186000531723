import React from 'react'

type Props = {
  type: 'danger' | 'success' | 'warning' | 'info'
  message: string
  handleCloseAlert: () => void
}

export const Alert = ({ type, message, handleCloseAlert }: Props) => {
  return (
    <div className={`alert alert-${type}`} role="alert">
      {message}
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleCloseAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}
