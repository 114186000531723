import Axios, { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'

export type Response = AxiosResponse<any> | { error: string }

function handleError(error: any, action: string) {
  let message = `Could not successfully ${action} the form submission. Try again shortly.`
  if (error.isAxiosError && error.message === 'Network Error')
    message = 'Could not connect to the server. Please check your connection and try again shortly.'
  if (error.isAxiosError && error.response?.data.detail) {
    if (typeof error.response.data.detail === 'string' && error.response.data.detail.includes('isJoi'))
      message = 'Please fill in all required fields marked with an asterik "*" and try again.'
    else message = error.response.data.detail
  }
  return { error: message }
}

const getAuthorizationHeaders = async () => {
  if (process.env.REACT_APP_ENV === 'local') {
    return {
      'x-vizzn-token': process.env.REACT_APP_VIZZN_TOKEN,
      'vizzn-user-id': process.env.REACT_APP_VIZZN_USER_ID,
    }
  }

  const flutterInAppWebViewToken = await (window as any).flutter_inappwebview.callHandler('getAuthToken')

  return {
    authorization: `Bearer ${flutterInAppWebViewToken}`,
  }
}

export const api = {
  create: async (body: {
    formId: string
    templateId: string
    submissionData: object
    isDraft: boolean
    dispatchEventId?: string
    siteMapShapeId?: string
  }): Promise<Response> => {
    try {
      const headers = await getAuthorizationHeaders()

      const r = await Axios.post(
        `https://${process.env.REACT_APP_API_URL}/v1/forms/${body.formId}/templates/${body.templateId}/submissions`,
        {
          isDraft: body.isDraft,
          submissionData: body.submissionData,
          dispatchEventId: body.dispatchEventId,
          siteMapShapeId: body.siteMapShapeId,
        },
        {
          headers: headers,
        }
      )
      return r
    } catch (error) {
      Sentry.captureException(error)
      return handleError(error, 'save')
    }
  },
  update: async (body: {
    formId: string
    templateId: string
    submissionData: object
    isDraft: boolean
    submissionId: string
  }): Promise<Response> => {
    try {
      const headers = await getAuthorizationHeaders()

      return Axios.put(
        `https://${process.env.REACT_APP_API_URL}/v1/forms/${body.formId}/templates/${body.templateId}/submissions/${body.submissionId}`,
        {
          isDraft: body.isDraft,
          submissionData: body.submissionData,
        },
        {
          headers: headers,
        }
      )
    } catch (error) {
      Sentry.captureException(error)
      return handleError(error, 'update')
    }
  },
  edit: async (body: {
    formId: string
    templateId: string
    submissionData: object
    submissionId: string
  }): Promise<Response> => {
    try {
      const headers = await getAuthorizationHeaders()

      return Axios.patch(
        `https://${process.env.REACT_APP_API_URL}/v1/forms/${body.formId}/templates/${body.templateId}/submissions/${body.submissionId}`,
        {
          submissionData: body.submissionData,
        },
        {
          headers: headers,
        }
      )
    } catch (error) {
      Sentry.captureException(error)
      return handleError(error, 'edit')
    }
  },
}
