import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useGet } from 'hooks/useGet'
import { Template, Submission } from 'types/template'
import { FormSubmission } from '../atoms/FormSubmission'
import { ErrorRetry } from 'atoms/ErrorRetry'
import '../scss/form-submission-view.scss'

type Props = RouteComponentProps<{ formId: string; templateId: string; submissionId: string }>

export const ViewFormSubmission = ({ match }: Props) => {
  const { formId, templateId, submissionId } = match.params
  const template = useGet<Template>(`forms/${formId}/templates/${templateId}`)
  const onGoingSubmission = useGet<Submission>(`forms/${formId}/templates/${templateId}/submissions/${submissionId}`)

  if (template.loading || onGoingSubmission.loading) return <span>Loading...</span>
  if (template.error) return <ErrorRetry reFetch={template.reFetch} />
  if (onGoingSubmission.error) return <ErrorRetry reFetch={onGoingSubmission.reFetch} />

  const isSubmitted = onGoingSubmission.data.status.name === 'Submitted'
  const editUrl = `/forms/${formId}/templates/${templateId}/submissions/${onGoingSubmission.data.id}/edit`

  return (
    <div style={{ margin: 10, width: 'calc(100% - 20px)' }} className="form-submission-view">
      <div>
        <div className="d-flex justify-content-between my-2">
          <h3>View Submission</h3>
          <div className="d-flex align-items-center">
            <span
              className="badge badge-success mx-2 p-2"
              style={{ backgroundColor: `#${onGoingSubmission.data.status.bgColor}` }}
            >
              {onGoingSubmission.data.status.name}
            </span>
            {isSubmitted && (
              <a href={editUrl} role="button" className="btn btn-primary active" onClick={() => window.history.back()}>
                Edit
              </a>
            )}
          </div>
        </div>
      </div>
      <FormSubmission formioJson={template.data.formioJson} submissionData={onGoingSubmission.data.formioData} mode="view" />
    </div>
  )
}
