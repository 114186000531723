import React, { useEffect, useState, createContext } from 'react'
import { NewFormSubmission } from 'pages/NewFormSubmission'
import { UpdateFormSubmission } from 'pages/UpdateFormSubmission'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ViewFormSubmission } from 'pages/ViewFormSubmission'
import { EditFormSubmission } from 'pages/EditFormSubmission'
import * as Sentry from '@sentry/react'
import Axios from 'axios'

function parseJwt(token: string) {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
  } catch (e) {
    return null
  }
}

type WindowAfterListener = Window &
  typeof globalThis & {
    flutter_inappwebview: {
      callHandler: (handler: 'getAuthToken' | 'goBack') => Promise<string>
    }
  }

export const Context = createContext<{ token: string; goBack: () => void } | null>(null)

export const App = () => {
  const [token, setToken] = useState('')
  const [isFlutterReady, setIsFlutterReady] = useState(false)
  const [isAxiosReady, setIsAxiosReady] = useState(false)

  window.addEventListener('flutterInAppWebViewPlatformReady', () => setIsFlutterReady(true))

  const getToken = async () => {
    const token = await (window as WindowAfterListener).flutter_inappwebview.callHandler('getAuthToken')
    setToken(token)
  }

  // const goBack = () => (window.location.href = 'https://google.com')

  const goBack = () => {
    if (process.env.REACT_APP_ENV === 'local') {
      alert('Submission was successfully submitted. Page will now refresh')
      window.location.reload()
    } else return (window as WindowAfterListener).flutter_inappwebview.callHandler('goBack')
  }

  const environment = process.env.REACT_APP_ENV
  const vizznToken = process.env.REACT_APP_VIZZN_TOKEN
  const vizznUserId = process.env.REACT_APP_VIZZN_USER_ID

  useEffect(() => {
    if (environment === 'local') setToken(vizznToken)
  }, [environment, vizznToken])

  useEffect(() => {
    if (environment !== 'local' && isFlutterReady) {
      getToken()
    }
  }, [isFlutterReady, environment])

  useEffect(() => {
    if (token) {
      const authContext = parseJwt(token)
      if (authContext) {
        for (const key of Object.keys(authContext)) {
          Sentry.setTag(key, authContext[key])
        }
      }
    }
  }, [token])

  useEffect(() => {
    if (token) {
      Axios.interceptors.request.use(
        (config) => {
          if (environment === 'local' && vizznUserId) {
            config.headers['vizzn-user-id'] = vizznUserId
            config.headers['x-vizzn-token'] = token
          } else {
            if (!config.headers.authorization) {
              config.headers.authorization = `Bearer ${token}`
            }
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      setIsAxiosReady(true)
    }
  }, [token, environment, vizznUserId])

  return (
    <>
      <BrowserRouter>
        <Context.Provider value={{ token, goBack }}>
          {isAxiosReady ? (
            <Switch>
              <Route path="/forms/:formId/templates/:templateId/submissions/new" component={NewFormSubmission} />
              <Route
                path="/forms/:formId/templates/:templateId/submissions/:submissionId/view"
                component={ViewFormSubmission}
              />
              <Route
                path="/forms/:formId/templates/:templateId/submissions/:submissionId/edit"
                component={EditFormSubmission}
              />
              <Route
                path="/forms/:formId/templates/:templateId/submissions/:submissionId"
                component={UpdateFormSubmission}
              />
              <div>404</div>
            </Switch>
          ) : (
            <span>Loading...</span>
          )}
        </Context.Provider>
      </BrowserRouter>
    </>
  )
}
