import { Response } from 'api'
import { Context } from 'App'
import { cloneDeep } from 'lodash'
import React, { useContext, useState } from 'react'
import { Form as FormioForm } from 'react-formio'
import { Alert } from './Alert'
import Axios from 'axios'
import './FormSubmission.scss'
import { badSignatureField } from './constants'

type Values = {
  submissionData: object
  isDraft: boolean
}

type Props = {
  handleSubmit?: (values: Values) => Promise<Response>
  formioJson: any
  submissionData?: object
  showSaveAsDraft?: boolean
  mode: 'new' | 'edit' | 'view' | 'update'
}

const showErrorsOnDirtyForm = true
const showSilentFormErrors = false

export const FormSubmission = ({ formioJson, submissionData: existingData, handleSubmit, showSaveAsDraft, mode }: Props) => {
  showSaveAsDraft = showSaveAsDraft === undefined ? true : showSaveAsDraft

  const [data, setData] = useState({})
  const [alertProps, setAlertProps] = useState(null)
  const [displayAlert, setDisplayAlert] = useState(false)
  const [valFormio, seValFormio] = useState(null)
  const context = useContext(Context)
  const formRef = React.useRef(null)
  const valuesRef = React.useRef<any[]>([])

  const setPreviewImages = () => {
    const files = document.querySelectorAll('.formio-component-file a')
    if (files.length) {
      files.forEach((file) => {
        const image = file.querySelector('.formio-component-file a img')
        if (!image) {
          const img = document.createElement('img')

          const href = file.getAttribute('href') || ''
          const isImg = href.includes('.jpg') || href.includes('.jpeg') || href.includes('.png')

          if (isImg) {
            //@ts-ignore
            file.style.display = 'flex'
            //@ts-ignore
            file.style.flexDirection = 'column'

            img.setAttribute('src', href || '')
            img.setAttribute('width', '200px')

            file.appendChild(img)
          }
        }
      })
    }
  }

  const fixSubmissionData = (submissionData: any) => {
    if (submissionData?.data)
      Object.keys(submissionData.data).forEach((key) => {
        if (submissionData.data[key] === badSignatureField) {
          submissionData.data[key] = undefined
        }
      })

    return submissionData
  }

  React.useCallback((form) => {
    formRef.current = form
  }, [])

  React.useEffect(() => {
    if (formioJson) {
      /* This code looks slower than using previous one but it will remember 
          previous API requests, so in several cases is actually faster
      */
      const updateComponent = async (component: any) => {
        if (process.env.REACT_APP_ENV === 'local' && component.dataSrc === 'url') {
          component.data.headers = [
            {
              key: 'x-vizzn-token',
              value: process.env.REACT_APP_VIZZN_TOKEN,
            },
            {
              key: 'vizzn-user-id',
              value: process.env.REACT_APP_VIZZN_USER_ID,
            },
          ]
        }

        if (component.type === 'datetime' && component.key.indexOf('dateTime') > -1) {
          component.format = 'yyyy-MM-d HH:mm:ss'
        }

        if (
          mode === 'new' &&
          component.type === 'datetime' &&
          (component.key.indexOf('autoFilledTime') > -1 || component.key.indexOf('dateTime')) > -1
        )
          component.defaultValue = new Date()

        if (mode !== 'view' && component.type === 'select' && component.dataSrc === 'url') {
          component.customOptions = {
            searchResultLimit: 999,
          }

          const dataUrl = component.data.url
          component.data.url = undefined
          component.data.headers = undefined
          component.dataSrc = 'values'

          const currentVal = valuesRef.current?.find((val) => val.url === dataUrl)

          if (currentVal) {
            component.data.values = currentVal.values
          } else {
            const { data } = await Axios.get<any>(dataUrl, {
              params: {
                limit: 999,
                skip: 0,
              },
            })

            valuesRef.current.push({
              url: dataUrl,
              values: data.data,
            })
            component.data.values = data.data
          }
        }

        if (component.components) {
          for (let i = 0; i < component.components.length; i++) {
            await updateComponent(component.components[i])
          }
        }
        if (component.columns) {
          for (let i = 0; i < component.columns.length; i++) {
            const column = component.columns[i]
            for (let j = 0; j < column.components.length; j++) {
              await updateComponent(column.components[j])
            }
          }
        }
        if (component.rows) {
          for (let i = 0; i < component.rows.length; i++) {
            const row = component.rows[i]
            for (let j = 0; j < row.length; j++) {
              const column = row[j]
              for (let k = 0; k < column.components.length; k++) {
                await updateComponent(column.components[k])
              }
            }
          }
        }
      }

      ;(async () => {
        formioJson.components = await Promise.all(
          formioJson.components.map(async (component: any) => {
            await updateComponent(component)
            return component
          })
        )
        seValFormio(formioJson)
      })()
    }
  }, [formioJson, mode])

  const handleFormioSubmit = async (req: { isDraft: boolean }) => {
    if (handleSubmit) {
      const response = await handleSubmit({ isDraft: req.isDraft, submissionData: { data: cloneDeep(data) } })
      //@ts-ignore
      if (response.error) {
        // https://stackoverflow.com/questions/67886044/formio-custom-error-message-when-the-submit-button-is-pressed-without-filling
        formRef.current.formio.emit('error')
        //@ts-ignore
        setAlertProps({ type: 'danger', message: response.error })
        setDisplayAlert(true)
        window.scrollTo(0, 0)
      } else {
        formRef.current.formio.emit('submitDone')
        setAlertProps({ type: 'success', message: 'Submission Completed' })
        setDisplayAlert(true)
        context?.goBack()
      }
    }
  }

  const handleCloseAlert = () => {
    setDisplayAlert(false)
    setAlertProps(null)
  }

  const handleFormioSubmitDraft = () => {
    const isValid = formRef.current?.formio.checkValidity(null, showErrorsOnDirtyForm, null, showSilentFormErrors)
    if (isValid) handleFormioSubmit({ isDraft: true })
    else {
      setAlertProps({
        type: 'danger',
        message: 'There are missing fields on your submission. Please review all required fields and submit again.',
      })
      setDisplayAlert(true)
      window.scrollTo(0, 0)
    }
  }

  React.useEffect(() => {
    // Formio take a few extra ms to be loaded
    setTimeout(() => {
      setPreviewImages()
    }, 500)
  }, [])

  if (!valFormio) return <div>Loading</div>
  return (
    <>
      {displayAlert && alertProps && (
        <Alert type={alertProps.type} message={alertProps.message} handleCloseAlert={handleCloseAlert} />
      )}
      {valFormio && (
        <FormioForm
          ref={formRef}
          form={valFormio}
          submission={fixSubmissionData(existingData)}
          options={{
            readOnly: !handleSubmit,
            noAlerts: true,
            // hooks: {
            //   customValidation: (submission: any, next: any) => {
            //     // https://help.form.io/developers/form-renderer#form-events
            //     // This runs before the http request for submission is made
            //     console.log('customValidation', { submission, next })
            //     return next()
            //   },
            // },
          }}
          onChange={(submission: any) => {
            setPreviewImages()
            setData(submission.data)
          }}
          onSubmit={async () => handleFormioSubmit({ isDraft: false })}
        />
      )}
      {handleSubmit && showSaveAsDraft === true && (
        <button className="btn btn-light" onClick={handleFormioSubmitDraft}>
          Save as Draft
        </button>
      )}
    </>
  )
}
