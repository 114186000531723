import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useGet } from 'hooks/useGet'
import { Template, Submission } from 'types/template'
import { FormSubmission } from '../atoms/FormSubmission'
import { api } from 'api'
import { ErrorRetry } from 'atoms/ErrorRetry'

type Props = RouteComponentProps<{ formId: string; templateId: string; submissionId: string }>

type Values = {
  submissionData: object
  isDraft: boolean
}

export const UpdateFormSubmission = ({ match }: Props) => {
  const { formId, templateId, submissionId } = match.params
  const template = useGet<Template>(`forms/${formId}/templates/${templateId}`)
  const onGoingSubmission = useGet<Submission>(`forms/${formId}/templates/${templateId}/submissions/${submissionId}`)

  if (template.loading || onGoingSubmission.loading) return <span>Loading...</span>
  if (template.error) return <ErrorRetry reFetch={template.reFetch} />
  if (onGoingSubmission.error) return <ErrorRetry reFetch={onGoingSubmission.reFetch} />

  const handleSubmit = async (values: Values) => {
    return api.update({
      isDraft: values.isDraft,
      submissionData: values.submissionData,
      formId,
      templateId,
      submissionId,
    })
  }

  return (
    <div style={{ margin: 10, width: 'calc(100% - 20px)' }}>
      <h3>Edit Submission</h3>
      <FormSubmission
        handleSubmit={handleSubmit}
        formioJson={template.data.formioJson}
        submissionData={onGoingSubmission.data.formioData}
        mode="update"
      />
    </div>
  )
}
